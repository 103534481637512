import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { mapStores } from 'pinia';
import moment from 'moment';
import componentI18n from '@/i18n/componentI18n';
import { useConstantStore } from '@/pinia_stores/constant';
import AvatarList from '@/components/users/AvatarList.vue';
export default defineComponent({
    name: 'RetroListItem',
    components: {
        AvatarList
    },
    ...componentI18n('components.retro_list'),
    props: {
        retro: { type: Object, required: true }
    },
    computed: {
        ...mapGetters(['session', 'locale']),
        ...mapStores(useConstantStore),
        finished() {
            return this.retro.phase === 'finished';
        },
        inProgress() {
            return moment(this.retro.startsAt).isBefore();
        },
        retroStartsAtDate() {
            const date = new Date(this.retro.startsAt);
            return new Intl.DateTimeFormat(this.locale, {
                day: 'numeric',
                month: '2-digit',
                year: 'numeric'
            }).format(date);
        },
        workspaceId() {
            return this.$route.params.workspaceId;
        },
        teamId() {
            return this.$route.params.teamId;
        },
        isTeamRetro() {
            // TODO: Check without depending on the url.
            return this.workspaceId && this.teamId;
        },
        to() {
            if (this.isTeamRetro) {
                return {
                    name: 'showTeamRetro',
                    params: { workspaceId: this.workspaceId, teamId: this.teamId, retroId: this.retro.id }
                };
            }
            else {
                return { name: 'showRetro', params: { retroId: this.retro.id } };
            }
        },
        activitiesMap() {
            return this.constantStore.activitiesMap;
        }
    },
    methods: {
        activityColor(name) {
            return this.activitiesMap.get(name).backgroundColor;
        },
        activityImage(name) {
            return this.activitiesMap.get(name).image;
        }
    }
});
